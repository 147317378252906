<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>

<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <v-card
  elevation="2" class="text-right"
>
<table class="table table-striped">
  <thead>
    <tr><th class="text-left">MAGASIN</th><th>TAUX POUR 1.00 USD</th></tr>
  </thead>
  <tbody style="margin-top:40px">
  <tr  v-for="rate in activeRates" :key="rate.id">
    <td class="text-left" style="vertical-align:middle">{{ rate.store.name }}</td>
    <td style="vertical-align:middle"><v-text-field
            label="HTG" style="width:100px;float:right;vertical-align: middle"
            solo
            v-model=rate.sales_rate
            v-on:change='update(rate)'
          >{{ rate.sales_rate }}</v-text-field></td>
  </tr>
</tbody>
</table>

</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'

export default {
  name: 'RatesView',
  methods: {
    load: function(){
      var self = this;
      this.$axios.get('/store-rates?include=store')
        .then(function (response) {
          self.rows = response.data
          self.enable = true          
        }).catch(function(error){
          console.log(error.message)
        })
    },
    update: function(rate){
      var url = '/store-rates/'+rate.id
      this.$axios.put(url+"?data="+encodeURIComponent(JSON.stringify(rate)))
      .then(response => {
        console.log(response.data)
      })
    }
  },
  mounted(){
    this.load()
  },
  computed: {
    activeRates: function(){
      return this.rows.filter(i => i.rate_id == 2)
    }
  },
  data: () => ({
      search: '',
      index: -1,
      items: [
        {
          text: 'TAUX DU JOUR',
          disabled: false,
          href: '/rates',
        }
      ],
      fluid: true,
      enable: false,
      headers: [
          {
            text: 'MAGASIN',
            align: 'start',
            sortable: false,
            value: 'store.name',
          },
          { text: 'TAUX DU JOUR', value: 'sales_rate' },
        ],
        rows: [],
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .theme--dark.v-btn.v-btn--has-bg{
    background-color:#003F74 !important;
  }

  .settingscontainer{
    margin-top:10px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red, .confirm_delete_button{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .text-center{
    text-align:center;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #003F74 !important;
    color: white!important;
    border-radius: 4px;
  }
  .delete_button{
    background:#ff5252 !important;
    color: white!important;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:-8px;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }

  .color-red{
    color:red!important;
  }c
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }

  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }
</style>